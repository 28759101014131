// utils/texts.js
export const texts = {
    en: {
      sendAnonymousMessage: "Send Anonymous Message",
      anonymousQuestionSent: "You have sent an anonymous question",
      sendPagePlaceholder: "Your referral link",
      shareLinkPrompt: "You can share this link to start receiving anonymous questions from others.",
      referralLink: "Your referral link",
      share: "Share Link",
      close: "Close",
      sendingMessageTo: "You are sending an anonymous message to",
      messageGuidelines: "Please ensure your message is respectful and does not contain harmful or offensive content.",
      yourQuestion: "Your Question",
      send: "Send",
      messageSent: "You have sent an anonymous message to",
      cancel: "Cancel",
      confirmSend: "Are you sure you want to send this message? It will be sent anonymously.",
      noMessages: "No messages",
      waitingForQuestions: "Waiting for incoming questions...",
      noReplies: "No replies",
      waitingForReplies: "Waiting for replies...",
      reply: "Reply",
      messageReply: 'Reply',
      replyDiv: "Reply: ",
      question: "Question",
      questionDiv: "Question: ",
      writeReply: "Write a reply",
      sendReply: "Send Reply",
      inboxIcon: "Inbox",
      sendIcon: "Send",
      placeholderIcon: "Profile",
      questions: "Questions",
      answers: "Answers",
      shareMessage: "Share message",
      sending: "Sending...",
      groupReplies: "No replies. Groups cannot send messages.",
      yes: "Yes",
      no: "No",
      sharePrompt: "Do you want to share this message?",

    },
    ru: {
      sendAnonymousMessage: "Отправить анонимное сообщение",
      anonymousQuestionSent: "Вы отправили анонимный вопрос",
      sendPagePlaceholder: "Ваша реферальная ссылка",
      shareLinkPrompt: "Вы можете поделиться этой ссылкой, чтобы начать получать анонимные вопросы от других.",
      referralLink: "Ваша реферальная ссылка",
      share: "Поделиться ссылкой",
      close: "Закрыть",
      sendingMessageTo: "Вы отправляете анонимное сообщение",
      messageGuidelines: "Убедитесь, что ваше сообщение уважительное и не содержит оскорбительного контента.",
      yourQuestion: "Ваш вопрос",
      send: "Отправить",
      messageSent: "Вы отправили анонимное сообщение",
      cancel: "Отменить",
      confirmSend: "Вы уверены, что хотите отправить это сообщение? Оно будет отправлено анонимно.",
      noMessages: "Сообщений нет",
      waitingForQuestions: "Ожидаем входящих вопросов...",
      noReplies: "Ответов нет",
      waitingForReplies: "Ожидаем ответов...",
      reply: "Ответить",
      messageReply: "Ответ",
      replyDiv: "Ответ: ",
      question: "Вопрос",
      questionDiv: "Вопрос: ",
      writeReply: "Написать ответ",
      sendReply: "Отправить ответ",
      inboxIcon: "Входящие",
      sendIcon: "Написать",
      placeholderIcon: "Профиль",
      questions: "Вопросы",
      answers: "Ответы",
      shareMessage: "Поделиться ответом",
      sending: "Отправка...",
      groupReplies: "Нет ответов. Группы не могут писать сообщения.",
      yes: "Да",
      no: "Нет",
      sharePrompt: "Хотите поделиться этим сообщением?",
    },
  };
  
  export const getText = (key, language) => texts[language][key] || texts.en[key];
  