import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  Pagination,
  BottomNavigation,
  BottomNavigationAction,
  Skeleton,
  Typography
} from "@mui/material";
import { getText } from "../utils/texts";

// helper to format creation_time: if today, show HH:MM; else DD.MM.YYYY.
const formatCreationTime = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();

  // check if the message was created today.
  const isToday = date.toDateString() === now.toDateString();

  if (isToday) {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  } else {
    // manually format DD.MM.YYYY with leading zeros.
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }
};

const InboxPage = ({
    currentMessages,
    currentReplies,
    inboxTab,
    setScreenByEvent,
    handleMessageClick,
    handleReplyClick,
    handlePaginationChange,
    language,
    itemsPerPage,
    messages,
    replies,
    currentPage,
    repliesPage,
    isGroup
  }) => {

  const [visibleMessages, setVisibleMessages] = useState([]);
  const [visibleReplies, setVisibleReplies] = useState([]);
  const listRef = useRef(null);
  const [clickedMessages, setClickedMessages] = useState(new Set());
  const [clickedReplies, setClickedReplies] = useState(new Set());

  const handleLocalMessageClick = (msg) => {
    setClickedMessages(prev => new Set(prev).add(msg.id));
    handleMessageClick(msg);
  };

  const handleLocalReplyClick = (reply) => {
    setClickedReplies(prev => new Set(prev).add(reply.id));
    handleReplyClick(reply);
  }

  useEffect(() => {
    setVisibleMessages(messages.slice(0, 20)); // Initial load
    setVisibleReplies(replies.slice(0, 20));
  }, [messages, replies]);

  const handleScroll = () => {
    if (!listRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      if (inboxTab === "questions" && visibleMessages.length < messages.length) {
        setVisibleMessages(messages.slice(0, visibleMessages.length + 20));
      } else if (inboxTab === "answers" && visibleReplies.length < replies.length) {
        setVisibleReplies(replies.slice(0, visibleReplies.length + 20));
      }
    }
  };

  useEffect(() => {
    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (listElement) {
        listElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [visibleMessages, visibleReplies, inboxTab]);

  return(
    <Box>
      <BottomNavigation
        value={inboxTab}
        onChange={(event, newValue) => setScreenByEvent(newValue)}
        showLabels
        sx={{ marginBottom: 2 }}
      >
        <BottomNavigationAction label={getText("questions", language)} value="questions" />
        <BottomNavigationAction label={getText("answers", language)} value="answers" />
      </BottomNavigation>
      <Box ref={listRef} sx={{ height: "80vh", overflowY: "auto" }}>
        {inboxTab === "questions" ? (
          <List>
          {visibleMessages.length > 0 ? (
            visibleMessages.map((msg) => (
              <ListItem
                button
                key={msg.id}
                divider
                onClick={() => handleLocalMessageClick(msg)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  transition: "background-color 0.3s",
                  "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
                }}
              >
                <ListItemText
                  primary={msg.shortText}
                  primaryTypographyProps={{
                    fontWeight: msg.is_read || clickedMessages.has(msg.id) ? "normal" : "bold"
                  }}
                />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="caption" color="textSecondary" sx={{ mr: 2 }}>
                    {formatCreationTime(msg.creation_time)}
                  </Typography>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMessageClick(msg);
                    }}
                    sx={{ textTransform: "none", fontSize: "0.8rem" }}
                  >
                    {getText("reply", language)}
                  </Button>
                </Box>
              </ListItem>
            ))
          ) : (
            <ListItem key="placeholder-question" divider>
              <ListItemText
                primary={getText("noMessages", language)}
                secondary={getText("waitingForQuestions", language)}
              />
            </ListItem>
          )}
        </List>
        ) : (
          <List>
            {visibleReplies.length > 0 && !isGroup ? (
              visibleReplies.map((reply) => (
                <ListItem
                  button
                  key={reply.id}
                  divider
                  onClick={() => handleLocalReplyClick(reply)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    transition: "background-color 0.3s",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
                  }}
                >
                  <ListItemText 
                    primary={`${getText("questionDiv", language)} ${reply.message_text}`}
                    secondary={`${getText("replyDiv", language)} ${reply.fullText}`}
                    primaryTypographyProps={{
                      fontWeight: reply.is_read || clickedReplies.has(reply.id) ? "normal" : "bold"
                    }}
                  />
                  <Typography variant="caption" color="textSecondary" sx={{ ml: "auto" }}>
                    {formatCreationTime(reply.creation_time)}
                  </Typography>
                </ListItem>
              ))
            ) : (
              <ListItem key="placeholder-reply" divider>
                <ListItemText primary={getText("noMessages", language)} secondary={getText("waitingForQuestions", language)} />
              </ListItem>
            )}
          </List>
        )}
      </Box>
    </Box>

    );
  };

export default InboxPage;
