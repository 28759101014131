import React, {useState, useEffect} from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import { getText } from "../utils/texts";
import axios from "axios";

const ModalView = ({
  open,
  onClose,
  selectedMessage,
  handleReplySubmit,
  language = "en",
  apiBaseUrl,
  chatId,
}) => {

  const [sharePromptOpen, setSharePromptOpen] = useState(false);
  const [replyText, setReplyText] = useState("");

  const readMessage = async (messageId) => {
    try {
      await axios.post(`${apiBaseUrl}/read_message`, {
        message_id: messageId,
      }, {
        headers: {
          "X-Telegram-Init-Data": window.Telegram.WebApp.initData
        }
      });
    } catch (error) {
      console.error("Failed to send read message request:", error);
    }
  };

  useEffect(() => {
      if (open && selectedMessage?.id && !selectedMessage?.is_read) {
        readMessage(selectedMessage.id);
      }
    }, [open, selectedMessage?.id]);


  const handleShareMessage = async (reply_text) => {

    console.log(replyText);
    console.log(reply_text);

    var textOfReply = selectedMessage?.reply_text;

    console.log("textOfReply: ", textOfReply);
    if (!textOfReply) {
      textOfReply = replyText;
      console.log("textOfReply: ", textOfReply);
    }


    try {
      const response = await axios.post(`${apiBaseUrl}/share_message`, {
        user_id: chatId,
        message_text: selectedMessage?.fullText,
        reply_text: textOfReply,
      });

      const { id: msg_id } = response.data.prepared_message_id;

      const WebApp = window.Telegram.WebApp;
      WebApp.shareMessage(msg_id, (success) => {
        if (success) {
          console.log("Message shared successfully!");
          setSharePromptOpen(false);
          setReplyText("");
          onClose();
        } else {
          console.error("Message sharing failed.");
          setSharePromptOpen(false);
          setReplyText("");
          onClose();
        }
      });
    } catch (error) {
      console.error("Error preparing or sharing the message:", error);
    }

  };

  return (
    <>
    <Modal open={open} onClose={onClose}>
      <Box
        p={3}
        bgcolor="background.paper"
        mx="auto"
        mt={5}
        maxWidth="600px"
        borderRadius={2}
        boxShadow={3}
      >
        <Typography variant="h6" color="text.primary" gutterBottom>
          {getText("question", language)}
        </Typography>
        <Box
          p={2}
          mb={2}
          sx={{
            bgcolor: (theme) =>
              theme.palette.mode === "dark" ? "#303841" : "#f5f5f5",
            borderRadius: 2,
            boxShadow: 2,
          }}
        >
          <Typography variant="body1" color="text.secondary">
            {selectedMessage?.fullText}
          </Typography>
        </Box>
        {selectedMessage?.reply_text ? (
          <Box
            mt={2}
            sx={{
              p: 2,
              bgcolor: (theme) =>
                theme.palette.mode === "dark" ? "#405d7c" : "#e3f2fd",
              borderRadius: 2,
              boxShadow: 2,
            }}
          >
            <Typography
              variant="subtitle2"
              color="text.primary"
              sx={{
                mb: 1,
                fontWeight: "bold",
                textTransform: "uppercase",
                letterSpacing: 1,
              }}
            >
              {getText("messageReply", language)}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {selectedMessage?.reply_text}
            </Typography>
          </Box>
        ) : (
            <TextField
              label={getText("writeReply", language)}
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              value={replyText}
              onChange={(e) => setReplyText(e.target.value)}
              sx={{ mt: 2 }}
            />
        )}
        <>
        {selectedMessage?.reply_text ? (
          <Button variant="contained" color="secondary" onClick={handleShareMessage}>
            {getText("shareMessage", language)}
          </Button>
        ) : (
          <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleReplySubmit(selectedMessage.id, replyText);
                setSharePromptOpen(true);
              }}
              sx={{ mt: 2 }}
          >
              {getText("sendReply", language)}
          </Button>
        )}
        <Button onClick={onClose}>{getText("close", language)}</Button>
        </>
      </Box>
    </Modal>

    <Modal open={sharePromptOpen}>
      <Box
        p={3}
        bgcolor="background.paper"
        mx="auto"
        mt={5}
        maxWidth="400px"
        borderRadius={2}
        boxShadow={3}
        textAlign="center"
      >
        <Typography variant="h6" color="text.primary" gutterBottom>
          {getText("sharePrompt", language)}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleShareMessage(replyText);
          }}
          sx={{ mt: 2, mr: 1 }}
        >
          {getText("yes", language)}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {setReplyText(""); onClose(); setSharePromptOpen(false);}}
          sx={{ mt: 2 }}
        >
          {getText("no", language)}
        </Button>
      </Box>
    </Modal>
    </>
  );
};

export default ModalView;
