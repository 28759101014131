import React from "react";
import { useEffect } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import { getText } from "../utils/texts";

import axios from "axios";

const ModalViewReplies = ({
  open,
  onClose,
  selectedReply,
  apiBaseUrl,
  language = "en",
}) => {

  const readReply = async (replyId) => {
    try {
      await axios.post(`${apiBaseUrl}/read_reply`, {
        message_id: replyId,
      }, {
        headers: {
          "X-Telegram-Init-Data": window.Telegram.WebApp.initData
        }
      });
    } catch (error) {
      console.error("Failed to send read reply request:", error);
    }
  }

  useEffect(() => {
    if (open && selectedReply?.id && !selectedReply?.is_read) {
      readReply(selectedReply.id);
    }
  }, [open, selectedReply?.id]);

  return(
    <Modal open={open} onClose={onClose}>
      <Box
        p={3}
        bgcolor="background.paper"
        mx="auto"
        mt={5}
        maxWidth="600px"
        borderRadius={2}
        boxShadow={3}
      >
        <Typography variant="h6" color="text.primary" gutterBottom>
          {getText("question", language)}
        </Typography>
        <Box
          p={2}
          mb={2}
          sx={{
            bgcolor: (theme) =>
              theme.palette.mode === "dark" ? "#303841" : "#f5f5f5",
            borderRadius: 2,
            boxShadow: 2,
          }}
        >
          <Typography variant="body1" color="text.secondary">
          {selectedReply?.message_text}
          </Typography>
        </Box>
          <Box
            mt={2}
            sx={{
              p: 2,
              bgcolor: (theme) =>
                theme.palette.mode === "dark" ? "#405d7c" : "#e3f2fd",
              borderRadius: 2,
              boxShadow: 2,
            }}
          >
            <Typography
              variant="subtitle2"
              color="text.primary"
              sx={{
                mb: 1,
                fontWeight: "bold",
                textTransform: "uppercase",
                letterSpacing: 1,
              }}
            >
              {getText("messageReply", language)}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {selectedReply?.fullText}
            </Typography>
          </Box>
        <Button onClick={onClose} sx={{ mt: 2 }}>
          {getText("close", language)}
        </Button>
      </Box>
    </Modal>
  );
}

export default ModalViewReplies;
